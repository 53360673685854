import React, { useState } from 'react';
import CreateTemplateComponent from './CreateTemplateComponent';

const JobDescriptionInputTemplate = {
  // "linkedin_jobtitle": {rows: 1, multiline: false, label: "Your Job Title", tooltip: "What is your Job Title? I.e. Recruiter, Office Manager, Marketing Manager", tooltip_items: [], value: "Recruiter", store: true},
  // "linkedin_authority_topics": {rows: 1, multiline: false, label: "Topics that you can advise on", tooltip: "What topics would people trust your opinion on? I.e. Careers, Progression, Industry Trends, Office Culture, Onboarding, Interview Tips, Negotiating etc.", tooltip_items: [], value: "Careers, Progression, Industry trends", store: true},
  "linkedin_target_audience": {rows: 1, multiline: false, label: "Your target audience", tooltip: "Who are you trying to generate interest with i.e. Job Seekers, Business Owners, specific Sector?", tooltip_items: [], value: "", store: true},
  "topic_to_cover": {rows: "auto", multiline: true, label: "What would you like to write about?", tooltip: "For the best results, write at least 5 words", tooltip_items: [], value: ""},
}

const url = "/linkedin_post"

const LinkedinPost = (props) => {

  return (
    <div className="CreatePage">   
        <div className="CreatePageTitle">
          Write a LinkedIn Post
        </div>
        <CreateTemplateComponent inputTemplate={JobDescriptionInputTemplate} url={url} refine_output={true}/>
    </div>
  );
};

export default LinkedinPost;