import React, { useState, useRef, useEffect } from 'react';
import { Modal, TextField, Select, FormControl, InputLabel, MenuItem, Button, OutlinedInput } from '@mui/material';
import { FiEdit, FiCheck } from 'react-icons/fi';
import { AiOutlineFileAdd } from 'react-icons/ai'
import Tooltip from '@mui/material/Tooltip';
import axios from 'axios'

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

const defaultTemplate = {TemplateName: "", TemplateContent: ""}

const JobDescriptionTemplate = (props) => {
  const [templates, setTemplates] = useState([])
  const [currentTemplateNum, setCurrentTemplateNum] = useState(-1)
  const [currentTemplate, setCurrentTemplate] = useState(defaultTemplate)
  const [menuOpen, setMenuOpen] = useState(false)
  const templateRef = useRef();
  const templateNumRef = useRef(-1)
  const templateTypeRef = useRef("New")

  function toggleMenu (type) {
    if (type == "New") {
        templateTypeRef.current = "New"
        setCurrentTemplate(defaultTemplate)
    } else {
        templateTypeRef.current = "Edit"
        if (templateNumRef.current && templates[templateNumRef.current]) {
            setCurrentTemplate(templates[templateNumRef.current])
        }
    }
    setMenuOpen(!menuOpen)
  }

  const keyPress = (e) =>{
    if(e.keyCode == 13){
       console.log('value', e.target.value);
    //    handleEditFinish()
    }
  }


  function handleChange(event) {
    console.log(event)
    if (event.target.value == -1){
        setCurrentTemplate(defaultTemplate)
        templateRef.current = defaultTemplate
        setCurrentTemplateNum(-1)
        templateNumRef.current = -1
        localStorage.setItem("JobDescriptionTemplateSelected", -1)
        props.updateTemplate("")
    } else {
        setCurrentTemplate(templates[event.target.value])
        templateRef.current = templates[event.target.value]
        setCurrentTemplateNum(event.target.value)
        templateNumRef.current = event.target.value
        localStorage.setItem("JobDescriptionTemplateSelected", event.target.value)
        if (templates[event.target.value]){
            props.updateTemplate(templates[event.target.value].TemplateContent)
        }
    }
    
  };

  function handleTemplateChange(event) {
    setCurrentTemplate(currentInput => {
        templateRef.current = {...currentInput, [event.target.id]: event.target.value}
        return {...currentInput, [event.target.id]: event.target.value}});
  };

  function SaveTemplate() {
    if (templateTypeRef.current == "New"){
        setTemplates(templates => {
            setCurrentTemplateNum(templates.length)
            templateNumRef.current = templates.length
            localStorage.setItem("JobDescriptionTemplateSelected", templates.length)
            props.updateTemplate(templateRef.current.TemplateContent)
            props.saveJobDescriptionTemplates([...templates, templateRef.current])
            return [...templates, templateRef.current]})
    } else {
        setTemplates(templates => {
            props.updateTemplate(templateRef.current.TemplateContent)
            templates[currentTemplateNum] = templateRef.current
            props.saveJobDescriptionTemplates(templates)
            return [...templates]})
    }

    setMenuOpen(false)
  }

  


  function DeleteTemplate() {
    setTemplates(templates => {
        templates.splice(currentTemplateNum, 1)
        setCurrentTemplateNum(-1)
        templateNumRef.current = -1
        localStorage.setItem("JobDescriptionTemplateSelected", -1)
        props.updateTemplate("")
        props.saveJobDescriptionTemplates(templates)
        return [...templates]})
    setMenuOpen(false)
  }

    useEffect(() => {
        var selectedTemplate = localStorage.getItem("JobDescriptionTemplateSelected")
        if (selectedTemplate !== null){
            console.log(selectedTemplate)
            setCurrentTemplateNum(parseInt(selectedTemplate))
            templateNumRef.current = parseInt(selectedTemplate)
        }
        var savedTemplates = props.jobDescriptionTemplates
        console.log("savedTemplates", savedTemplates)
        if (savedTemplates && savedTemplates.length > 0){
            setTemplates(savedTemplates)
            if (selectedTemplate !== null && selectedTemplate != -1){
                setCurrentTemplate(savedTemplates[selectedTemplate])
                templateRef.current = savedTemplates[selectedTemplate]
                props.updateTemplate(savedTemplates[selectedTemplate].TemplateContent)
            }
        }
    }, [])

    useEffect(() => {
        console.log("props.jobDescriptionTemplates", props.jobDescriptionTemplates)
        if (props.jobDescriptionTemplates !== templates) {
            setTemplates(props.jobDescriptionTemplates)
            console.log("setting templates")
            console.log(currentTemplateNum)
            if (templateNumRef.current !== null && templateNumRef.current != -1 && props.jobDescriptionTemplates[templateNumRef.current]){
                setCurrentTemplate(props.jobDescriptionTemplates[templateNumRef.current])
                templateRef.current = props.jobDescriptionTemplates[templateNumRef.current]
                props.updateTemplate(props.jobDescriptionTemplates[templateNumRef.current].TemplateContent)
            }
        }

    }, [props.jobDescriptionTemplates])


  return (
    
         <>
        <Tooltip arrow classes={{ popper: "TaylrTooltip" }} title={<div className="TooltipContents">{props.tooltip}</div>} placement="right" >
           <div className="TemplateSelectRow">
            <Select
            displayEmpty
            value={currentTemplateNum}
            onChange={handleChange}
            input={<OutlinedInput />}
            renderValue={(selected) => {
                if (!templates[selected]) {
                    return <em>Select a Template</em>;
                }

                return templates[selected].TemplateName;
            }}
            MenuProps={MenuProps}
            inputProps={{ 'aria-label': 'Without label' }}
            >
            {templates.length > 0 && [<MenuItem value={-1}>
                <em>None</em>
            </MenuItem>,
            templates.map((Template, index) => (
                <MenuItem
                key={index}
                value={index}
                >
                {Template.TemplateName}
                </MenuItem>
            ))]}
            <MenuItem onClick={() => {toggleMenu("New")}} className="MenuItemDefault"><AiOutlineFileAdd style={{marginRight:"3px"}}/>Add a Template to use</MenuItem>
            </Select>
                <div className="TemplateButtons">
                    {templates[currentTemplateNum] && <Button onClick={() => {toggleMenu("Edit")}} className="NewButtonSmall"><FiEdit/></Button>}
                    <Button onClick={() => {toggleMenu("New")}} className="NewButtonSmall"><AiOutlineFileAdd/></Button>
                </div>
            </div>
        </Tooltip>
        <Modal open={menuOpen} onClose={() => {setMenuOpen(false)}}>
            <div className="ModalContainer">
                <div className="ModalContent">
                    <div className="ModalTitle">Add a Template</div>
                    <div className="ModalInputContent">
                        <div className="CreatePageInputLabel">
                            Template Name
                        </div>
                        <Tooltip arrow classes={{ popper: "TaylrTooltip" }} title={<div className="TooltipContents">Name your Template</div>} placement="right" >
                            <div className="CreatePageOutput">
                            <TextField 
                                    variant="outlined" 
                                    onKeyDown={keyPress}
                                    multiline = {false}
                                    id={"TemplateName"}
                                    onChange={handleTemplateChange}
                                    rows={1}
                                    value={currentTemplate["TemplateName"]}/>
                            </div>
                        </Tooltip>
                        <div className="CreatePageInputLabel">
                            Example Job Description
                        </div>
                        <Tooltip arrow classes={{ popper: "TaylrTooltip" }} title={<div className="TooltipContents">Copy and Paste a Job Description here that you would like Taylr to mimic</div>} placement="right" >
                            <div className="CreatePageOutput">
                                <TextField 
                                    variant="outlined" 
                                    onKeyDown={keyPress}
                                    multiline = {true}
                                    id={"TemplateContent"}
                                    onChange={handleTemplateChange}
                                    rows={20}
                                    value={currentTemplate["TemplateContent"]}/>
                            </div>
                        </Tooltip>
                    </div>
                    <div className="TemplateButtons">
                        <Button className="CopyButton" onClick={SaveTemplate}>Save</Button>
                        {templateTypeRef.current == "Edit" && <Button className="DeleteButton" onClick={DeleteTemplate}>Delete</Button>}
                    </div>
                </div>
            </div>
        </Modal>
    </>
  );
};

export default JobDescriptionTemplate;