import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useLocation, useOutletContext } from "react-router-dom";

function Profile() {
    const outletProps = useOutletContext()
    console.log(outletProps)

    let plan_name
    if (outletProps.userData?.creditData?.plan){
        plan_name = outletProps.userData?.creditData?.plan
        if (plan_name.includes("_")) {
            plan_name = plan_name.split("_")[0]
        }
    }
  return (
    <div className="CreatePage">   
        <div className="CreatePageTitle">
            Your Settings
        </div>
        <div className="SettingsPage">
            <div className="SettingsPageSection">
                <div className="SettingsPageSectionTitle">
                    Billing
                </div>
                <div className="SettingsPageRow">
                    <div className="SettingsPageLeft">
                    Plan:
                    </div>
                    <div className="SettingsPlanName">
                        {plan_name}
                    </div>
                </div>
                <Link to="/settings/plans"  className="BreadcrumbText BreadcrumbLink" style={{textDecoration: "underline"}}>
                    Choose your plan
                </Link>

            </div>
        </div>

    </div>
  );
}

export default Profile;