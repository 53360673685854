import React, { useState } from 'react';
import CreateTemplateComponent from './CreateTemplateComponent';

const JobDescriptionInputTemplate = {
  "template": {type: "job_description_template", label:[<span>Template<span className="Optional">Optional</span></span>], tooltip:"Want more consistent results, or a specific writing style? Add an example and Taylr will try to replicate it.", value: ""},
  "job_description": {rows: "auto", multiline: true, label: "Job Description to Make Anonymous", tooltip: "Paste the Job Description you want Taylr to Anonymise", tooltip_items: [], value: "", limit: 500},
}

const AnonJob = (props) => {

  return (
    <div className="CreatePage">   
        <div className="CreatePageTitle">
          Anonymise a Job Advert
        </div>
          <CreateTemplateComponent inputTemplate={JobDescriptionInputTemplate} url={"/agencify_job_description"} refine_output={true}/>
    </div>
  );
};

export default AnonJob;