import React from 'react';
import { useOutletContext, BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";
import './create.css'
import { BsLinkedin } from 'react-icons/bs'

const Create = (props) => {

    const outletProps = useOutletContext()
    console.log(outletProps)
  return (
    <div className="CreatePage">   
        <div className="CreatePageTitle">
            Taylr some content
        </div>
        <div className="CreatePageGrid">
            <Link to="job-post" className="CreateBox">
                <div className="CreateBoxLogo">
                    ✏️
                </div>
                <div className="CreateBoxText">
                    Job Advert
                </div>
                <div className="CreateBoxSubtitle">
                    Create a job advert that follows best practices to maximise the number of applications received
                </div>
            </Link>
            <Link to="agencify" className="CreateBox">
                <div className="CreateBoxLogo">
                    🕵
                </div>
                <div className="CreateBoxText">
                    Anonymise a Job Advert
                </div>
                <div className="CreateBoxSubtitle">
                    Remove identifying information from a job advert and rewrite it from an agency perspective
                </div>
            </Link>
            {/* <Link to="outreach-email" className="CreateBox">
                <div className="CreateBoxText">
                    Outreach Email
                </div>
            </Link> */}
            <Link to="boolean-search" className="CreateBox">
                <div className='CreateBoxLogo'>
                    🔍
                </div>
                <div className="CreateBoxText">
                    Boolean Search
                </div>
                <div className="CreateBoxSubtitle">
                    Use Taylr to generate and refine the perfect search query
                </div>
            </Link>
            
            <Link to="linkedin-job-ad" className="CreateBox">
                <div className='CreateBoxLogo'>
                    <BsLinkedin className="LinkedInLogo" />
                </div>
                <div className="CreateBoxText">
                    LinkedIn Job Ad
                </div>
                <div className="CreateBoxSubtitle">
                    Convert a job description into a short & catchy LinkedIn job ad for your feed
                </div>
            </Link>
            <Link to="linkedin-post" className="CreateBox">
                <div className='CreateBoxLogo'>
                    <BsLinkedin className="LinkedInLogo" />
                </div>
                <div className="CreateBoxText">
                    LinkedIn Post
                </div>
                <div className="CreateBoxSubtitle">
                    Create a LinkedIn post designed to grab a reader's attention and optimised for the LinkedIn Algorithm
                </div>
            </Link>
            <Link to="linkedin-post-ideas" className="CreateBox">
                <div className='CreateBoxLogo'>
                    <BsLinkedin className="LinkedInLogo" />
                </div>
                <div className="CreateBoxText">
                    LinkedIn Post Ideas
                </div>
                <div className="CreateBoxSubtitle">
                    Writer's block? Generate some ideas for your next LinkedIn post
                </div>
            </Link>
            <Link to="linkedin-poll" className="CreateBox">
                <div className='CreateBoxLogo'>
                    <BsLinkedin className="LinkedInLogo" />
                </div>
                <div className="CreateBoxText">
                    LinkedIn Poll
                </div>
                <div className="CreateBoxSubtitle">
                    Generate a LinkedIn Poll for more engagement
                </div>
            </Link>
            <Link to="email-pitch-candidate" className="CreateBox">
                <div className='CreateBoxLogo'>
                    📧
                </div>
                <div className="CreateBoxText">
                    Client Email - Pitch a Candidate
                </div>
                <div className="CreateBoxSubtitle">
                    Write an email to a client, pitching a candidate using the AIDA marketing framework
                </div>
            </Link>
            <Link to="email-pitch-job" className="CreateBox">
                <div className='CreateBoxLogo'>
                    📧
                </div>
                <div className="CreateBoxText">
                    Candidate Email - Pitch a Job
                </div>
                <div className="CreateBoxSubtitle">
                    Write an email to a candidate, pitching them a job opportunity using the AIDA marketing framework
                </div>
            </Link>

            <div className="WIPBox">
                <div className="CreateBoxLogo">
                    🚧
                </div>
                <div className="CreateBoxText">
                    Work In Progress
                </div>
                <div className="CreateBoxSubtitle">
                    Taylr is a work in progress, we'll be adding more features here over time. Please let us know if there's anything you'd like to see!
                </div>
            </div>
        </div>

    </div>
  );
};

export default Create;