import React, { useEffect, useState, useRef } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import "./Dashboard.css";
import { auth, db, logout } from "../firebase";
import { query, collection, getDocs, where } from "firebase/firestore";
import { useSelector } from 'react-redux';
import axios from 'axios'
import DashboardLayout from "../components/DashboardLayout";
import { Routes, Route, Outlet  } from "react-router-dom";


let backend_url = process.env.BACKEND_URL || `http://localhost:5000` //`http://localhost:5000`;//`https://api.taylr.ai`

if (process.env.NODE_ENV == "production"){
  backend_url = `https://api.taylr.ai`
}
// backend_url = `

function Dashboard(props) {
  const [user, loading, error] = useAuthState(auth);
  const [userData, setUserData] = useState({});
  const [files, updateFiles] = useState([]);
  const [documentName, setDocumentName] = useState('');
  // const token = useSelector(user => {console.log(user); return user.auth.token} );
  const retryRef = useRef(0)
  const documentNameRef = useRef("")
  const jobDescriptionTemplates = useRef([])
  const navigate = useNavigate();

  const saveJobDescriptionTemplates = async (currentTemplates, store) => {
    jobDescriptionTemplates.current = currentTemplates
    console.log(currentTemplates)
    let token = user?.stsTokenManager?.accessToken
    if (user?.stsTokenManager?.accessToken) {
      if (user.stsTokenManager.expirationTime < Date.now()) {
        console.log("refreshing token")
        user.getIdToken({forceRefresh: true}).then(token => {
          saveJobDescriptionTemplates(currentTemplates)
        })
        return
      }
    }
    if (store !== false){
      const response = await axios.post(backend_url+"/save_templates",  {data: {templates: currentTemplates}}, {headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      }},)
    }
  }

  async function fetchTemplates (token) {
    console.log("fetchTemplates")
    const response = await axios.get(backend_url+"/fetch_templates",   {headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
    }},)
    if (response.data[0] && response.data[0].templates && response.data[0].templates.length > 0){
        const storedTemplates = JSON.parse(response.data[0].templates)
        jobDescriptionTemplates.current = storedTemplates
    }
}

  const fetchUserInfo = async (token) => {
    try {
      const response = await axios.get(backend_url+"/user_info",   {headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      }},)

      setUserData(response.data);
    } catch (err) {
      console.error(err);
      // console.log(err.stack)
      // console.log(err?.response?.data)
      if (err?.response?.status==403) {
        if (err?.response?.data?.code === "auth/id-token-expired") {
          console.log("token expired")
          auth.currentUser.getIdToken({forceRefresh: true}).then(token => {
            fetchUserInfo(token)
          })
        }
      }
      // alert("An error occured while fetching user data");
    }
  };

  const setCredits = (newCredits) => {
    console.log("setting credits to ", newCredits)
    setUserData(prevUserData => {return {...prevUserData, creditData: {...prevUserData.creditData, credits: newCredits}}})
  };


  const saveData = async (data) => {
    //send to database to save
    try {
      let token = user?.stsTokenManager?.accessToken
      if (user?.stsTokenManager?.accessToken) {
        if (user.stsTokenManager.expirationTime < Date.now()) {
          console.log("refreshing token")
          user.getIdToken({forceRefresh: true}).then(token => {
            saveData(data)
          })
          return
        }
      }
      const savedData =  await axios.post(backend_url+"/save-draft", {data},   {headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      }},)
      retryRef.current = 0
      if(savedData.status === 200) {
        updateFiles(prevFiles => {
          const stateArray = [...prevFiles]
          const index = stateArray.findIndex(obj => obj.documentId === data.documentId);
          data.modified_at = new Date().toISOString()
          if (index !== -1) {
            // Modify the existing object
            stateArray[index] = { ...stateArray[index], ...data };

          } else {
            // Add the new object
            data.created_at = new Date().toISOString()
            stateArray.unshift(data);
          }
          return stateArray
        })
      }
    } catch (err) {
      retryRef.current += 1
      if (retryRef.current > 4) return
      console.error(err);
      if (err?.response?.status==403) {
        if (err?.response?.data?.code === "auth/id-token-expired") {
          console.log("token expired")
          auth.currentUser.getIdToken({forceRefresh: true}).then(token => {
            //try again
            saveData(data)
          })
        }
      }
    }
  
  };

  const fetchData = async () => {
    let token = user?.stsTokenManager?.accessToken
    const drafts = await axios.get(backend_url+"/get-drafts",   {headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    }},)
    updateFiles(drafts.data)
    console.log(drafts)
  }

  useEffect(() => {
    if (user?.stsTokenManager?.accessToken) {
      if (user.stsTokenManager.expirationTime < Date.now()) {
        console.log("refreshing token")
        user.getIdToken({forceRefresh: true})
      } else {
        console.log("fetching user info")
        fetchUserInfo(user.stsTokenManager.accessToken);
        fetchData()
        fetchTemplates(user.stsTokenManager.accessToken)
      }
    }
  }, [user]);

  useEffect(() => {
    if (loading) return;
    if (!user) return navigate("/login");
  }, [user, loading]);

  return (
    <DashboardLayout userData={userData} user={user} logout = {logout} files = {files} updateFiles = {updateFiles} documentName = {documentName} documentNameRef = {documentNameRef} setDocumentName = {setDocumentName}>
      <Outlet context={{user, userData, logout, setCredits, backend_url, saveData, files, documentName, documentNameRef, setDocumentName, jobDescriptionTemplates: jobDescriptionTemplates.current, saveJobDescriptionTemplates}}/>
    </DashboardLayout>
  );
}

export default Dashboard;
