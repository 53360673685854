import React, { useEffect, useState } from 'react';
import { useOutletContext, useLocation, BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";
import { MdDashboard, MdLogout, MdChevronRight } from 'react-icons/md'
import { BsFileEarmarkText } from 'react-icons/bs'
import { IoFileTray } from 'react-icons/io5'
import { MdHelpOutline } from 'react-icons/md'
import { FaCoins } from 'react-icons/fa'
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import { sendEmailVerification } from 'firebase/auth';
import DocumentName from './DocumentName';

const loc_lookups = {"create": "Create", "job-post": "Job Advert", "agencify": "Anonymise", "email-pitch-candidate": "Email - Pitch a Candidate", "email-pitch-job": "Email - Pitch a Job", "linkedin-job-ad": "LinkedIn Job Ad", "linkedin-post-ideas": "LinkedIn Post Ideas", "linkedin-post": "LinkedIn Post", "linkedin-poll": "LinkedIn Poll", "history": "History", "boolean-search": "Boolean Search"}

const DashboardLayout = (props) => {
    const [emailVerificationOpen, setEmailVerificationOpen] = useState(false)
    const [emailVerificationSent, setEmailVerificationSent] = useState(false)

    const location = useLocation();

    useEffect(() => {
        if (props?.user?.emailVerified === false){
            setEmailVerificationOpen(true)
        }
        // user.sendEmailVerification()
    }, [props?.user?.emailVerified])


    let credits_message
    if (props?.user?.emailVerified == false)  {
        credits_message = <div className="CreditsMessage" onClick={() => {setEmailVerificationOpen(true)}}>Email Unverified</div>
    }


    let breadcrumbList = []
    if (location){
        for(const [index, loc] of location.pathname.split("/").slice(1).entries()){
            let location_name = loc
            if (loc_lookups[loc]){
                location_name = loc_lookups[loc]
            }
            if (location.pathname.split("/")?.length-1 > index+1){
                breadcrumbList.push(<Link to={"/"+loc} className="BreadcrumbText BreadcrumbLink">{location_name}</Link>)
                breadcrumbList.push(<MdChevronRight className="BreadcrumbDivider"/>)
            } else {
                breadcrumbList.push(<div className="BreadcrumbText">{location_name}</div>)
            }
        }
        if (location.pathname.startsWith("/create/")){
            breadcrumbList.push(<MdChevronRight className="BreadcrumbDivider"/>)
            breadcrumbList.push(<DocumentName files={props.files} updateFiles={props.updateFiles} documentName = {props.documentName} setDocumentName = {props.setDocumentName} documentNameRef = {props.documentNameRef}/>)
        }
    }

    let creditsNumberClass = ""
    console.log(props.userData)
    let credits = "Loading Credits..."
    if (props.userData && props.userData.creditData && props.userData.creditData.creditsTotal){
        credits = `${(props.userData?.creditData?.creditsTotal - props.userData?.creditData?.credits).toLocaleString()}/${props.userData?.creditData?.creditsTotal?.toLocaleString()} credits used`
        if (props.userData.creditData.credits <= 0){
            creditsNumberClass = " CreditsNumberRed"
            if (props.userData.creditData.plan == "trial"){
                credits_message = <Link to="/settings/plans" className="CreditsLink">Upgrade your plan</Link>
            } else {
                credits_message = <Link to="/settings/plans" className="CreditsLink">Upgrade your plan</Link>
            }
        }
        if (props.userData.creditData.plan == "trial_timed"){
            if (props.userData.creditData.created._seconds + props.userData.creditData.trial_length*60*60*24 < Math.floor(Date.now() / 1000)){
                //trial expired
                creditsNumberClass = " CreditsNumberRed"
                credits = "Trial Expired"
                credits_message = <Link to="/settings/plans" className="CreditsLink">Upgrade your plan</Link>
            } else {
                //x day remaining
                credits_message = <div className="CreditsMessage CreditsMessageInfo">Trial ends in {Math.ceil((props.userData.creditData.created._seconds + props.userData.creditData.trial_length*60*60*24 - Math.floor(Date.now() / 1000))/60/60/24)} days</div>
            }
        }
    }

  return (
    <div className="dashboard">   

        <div className="dashboard-layout">

            <div className="nav-buttons">
                <div className="nav-buttons-top">
                    <Link to="/create" className="dashboard-logo">
                        <img src="/taylrlogo.png" className="TaylrLogo" alt="Taylr Logo"/>
                        TAYLR.<span className="dashboard-logo-ai">AI</span>
                    </Link>   
                    {/* <Link to="/" className="nav-button"><MdDashboard className="navIcon DashboardIcon"/> Dashboard</Link> */}
                    <Link to="/create"  className="nav-button"><BsFileEarmarkText className="navIcon CreateIcon"/> Create</Link>
                    <Link to="/history"  className="nav-button"><IoFileTray className="navIcon SavedIcon"/> History</Link>
                </div>
                <div className="nav-buttons-bottom">
                    <div className="navCredits">
                        <div className="CreditsTitle"><FaCoins className="navIcon CreditsIcon"/> AI Credits</div> 
                        <div className="CreditsBar">{props.userData.creditData && "credits" in props.userData.creditData && <div className="CreditsBarFilled" style={{width: (100-props.userData.creditData.credits*100/props.userData.creditData.creditsTotal).toFixed(1)+"%"}}></div>}</div>
                        <div className={'CreditsNumber'+creditsNumberClass}>{credits}</div>
                        {credits_message}
                    </div>
                    <Link to="/help" className="nav-button"><MdHelpOutline className="navIcon HelpIcon"/> Help</Link>
                    <div className="nav-button" onClick={props.logout}>
                       <MdLogout className="navIcon LogoutIcon"/> Logout
                    </div>
                </div>
            </div>
            <div className="dashboard-main">
                <div className="dashboard-header">
                    <Link to="/settings" className="dashboard-user-info">
                        <div className="dashboard-username">{props.user?.displayName}</div>
                        <img className="dashboard-userimg" src={props.userData.picture}/>
                    </Link>
                    <div className="Breadcrumbs">{breadcrumbList}</div>
                </div>
                <div className="dashboard-content">
                    {props.children}
                </div>
                <div className="DashboardDisclaimer">Please note that you are solely responsible for the content you post online, and Taylr assumes no liability for any consequences resulting from such content.</div>
            </div>
 
        </div>
        <Modal
            open={emailVerificationOpen}
            onClose={() => setEmailVerificationOpen(false)}
            className="TaylrModal"
        >
            <div className="TaylrModalContent">
                <div className="TaylrModalTitle">Your Email Isn't Verified Yet</div>
                <div className="TaylrModalContentText">Please check your email and click the link to verify, then refresh the page.</div>
                <div className="TaylrModalContentText">Didn't receive an email yet?</div>
                <Button variant="contained" onClick={() => {
                    sendEmailVerification(props.user); 
                    setEmailVerificationSent(true); 
                    setTimeout(()=> {setEmailVerificationSent(false)}, 2000)}} className="SubmitButton">Send Verification Email</Button>
                <div className="TaylrModalContentSentMessage">{emailVerificationSent? "Email Sent!":""}</div> 
            </div>
        </Modal>
    </div>
  );
};

export { loc_lookups }

export default DashboardLayout;