import React from 'react';
import { Link, useNavigate, useLocation, useOutletContext } from "react-router-dom";
import Button from '@mui/material/Button';
import './settings.css'

const type = "live" //test
const prices = {live: {
    starter: "price_1MlV1DBGc9icGpbNhJ68Qy1w",
    flex: "price_1MfmA2BGc9icGpbNGa0EAiL6",
}, 
test: {
    starter: "price_1Mfm7EBGc9icGpbNvAv98ugX",
    flex: "price_1MfkGcBGc9icGpbNXZLe4NHN",
}}

function Plans() {
    const outletProps = useOutletContext()
    const planClick = () => {

    }

    if (!outletProps.user){
        return <div className="CreatePage">   
            <div className="CreatePageTitle">
                Our Plans
            </div>
            <div className="PlansPage">
                Loading your account information...
            </div>
        </div>
    }
    // console.log(outletProps)
  return (
    <div className="CreatePage">   
        <div className="CreatePageTitle">
            Our Plans
        </div>
        <div className="PlansPage">
            <div className="PlansContainer">

                <div className="Plan">
                    <div className="PlanTitle">
                        Single User
                    </div>
                    <div className="PlanDescription">
                        Ideal for an individual recruiter looking to save time
                    </div>
                    <div className="PlanPrice">
                        <div className="PoundIcon">£</div>
                        <div className="PlanPriceNumber">29</div>
                        <div className="PlanPriceInterval">/mo</div>
                    </div>
                    <div className="PlanCredits">35,000 credits per month</div>
                    <div className="PlanNumbeUsers">Single User</div>
                    <div className="PlanButtons">
                        {/* <Button onClick={planClick} className="LoginButton" variant="contained">Check out</Button> */}
                        <form action={outletProps.backend_url+"/create-checkout-session"} method="POST">
                        {/* Add a hidden field with the lookup_key of your Price */}
                            <input type="hidden" id = "price" name="price" value={prices[type].starter} />
                            <input type="hidden" id = "quantity" name="quantity" value={35000} />
                            <input type="hidden" id="customer_email" name="customer_email" value={outletProps.user.email}/>
                            <input type="hidden" id="uid" name="uid" value={outletProps.user.uid}/>
                            <Button id="checkout-and-portal-button"  className="PlanButton LoginButton"  type="submit">
                                Start Now
                            </Button>
                        </form>
                    </div>
                    <div className="PlanBilling">
                        Billed Monthly
                    </div>
                </div>
                <div className="Plan">
                    <div className="PlanTitle">
                        Business
                    </div>
                    <div className="PlanDescription">
                        Help to make your team more efficient
                    </div>
                    <div className="PlanPrice">
                        <div className="PoundIcon">£</div>
                        <div className="PlanPriceNumber">49</div>
                        <div className="PlanPriceInterval">/mo</div>
                    </div>
                    <div className="PlanCredits">80,000 credits per month</div>
                    <div className="PlanNumbeUsers">Up to 10 Users</div>
                    <div className="PlanButtons">
                        {/* <Button onClick={planClick} className="LoginButton" variant="contained">Check out</Button> */}
                        <form action={outletProps.backend_url+"/create-checkout-session"} method="POST">
                        {/* Add a hidden field with the lookup_key of your Price */}
                            <input type="hidden" id = "price" name="price" value={prices[type].flex} />
                            <input type="hidden" id = "quantity" name="quantity" value={80000} />
                            <input type="hidden" id="customer_email" name="customer_email" value={outletProps.user.email}/>
                            <input type="hidden" id="uid" name="uid" value={outletProps.user.uid}/>
                            <Button id="checkout-and-portal-button"  className="PlanButton CheckoutButton"  type="submit">
                                Start Now
                            </Button>
                        </form>
                    </div>
                    <div className="PlanBilling">
                        Billed Monthly
                    </div>
                </div>
            </div>
        </div>

    </div>
  );
}

export default Plans;