import React, { useState } from 'react';
import CreateTemplateComponent from './CreateTemplateComponent';

const JobDescriptionInputTemplate = {
  "client_name": {rows: 1, multiline: false, label: "Client Name", tooltip: "The client name. Can also be a variable (ie. #ClientFirstName#) for email automation systems", value: ""},
  "reason": {rows: 3, multiline: true, label: "Reason for Contacting", tooltip: "ie. I just got off the phone with a great candidate", tooltip_items: [], value: ""},
  "candidate_profile": {rows: "auto", multiline: true, label: "Candidate Profile", tooltip: "Add some information about the candidate, such as:", tooltip_items: ["Position/Title", "Location", "Experience", "Specific skills", "Software skills", "Any particular projects to highlight"], value: "", limit: 500},
}

const EmailPitchCandidate = (props) => {

  return (
    <div className="CreatePage">   
        <div className="CreatePageTitle">
          Pitch a Candidate
        </div>
        <CreateTemplateComponent inputTemplate={JobDescriptionInputTemplate} url={"/email-pitch-candidate"} refine_output={true}/>
    </div>
  );
};

export default EmailPitchCandidate;