import React, { useState } from 'react';
import CreateTemplateComponent from './CreateTemplateComponent';

const JobDescriptionInputTemplate = {
  "job_description": {rows: "auto", multiline: true, label: "Job Description", tooltip: "Paste the full job description that you would like to turn into a LinkedIn Post", tooltip_items: [], value: ""},
  "keep_short": {type: "checkbox", default: false, label: "Under 700 Characters (estimated)", tooltip: "We'll try to keep the output under 700 characters (100 words)", tooltip_items: [], value: ""},
}

const url = "/linkedin-job-ad"

const LinkedinJobAd = (props) => {

  return (
    <div className="CreatePage">   
        <div className="CreatePageTitle">
          Create a LinkedIn Job Ad
        </div>
        <CreateTemplateComponent inputTemplate={JobDescriptionInputTemplate} url={url} refine_output={true}/>
    </div>
  );
};

export default LinkedinJobAd;