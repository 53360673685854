import React from 'react';
import { useOutletContext, BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";
import './saved.css'
import { DataGrid } from '@mui/x-data-grid';
import moment from 'moment';
import {FiEdit} from 'react-icons/fi'

const columns = [
//   { field: 'draft_id', headerName: 'ID', width: 70, hidden: true },
  { field: 'documentName', headerName: 'File Name', width: 250, },
  { field: 'type', headerName: 'Type', width: 200, },
  {
    field: 'response',
    headerName: 'Preview',
    width: 200,
    sortable: false,
     valueGetter: (params) =>
      `${params.row.response.slice(0, 200)}`,
    flex: 1
  },
  {
    field: 'modified_at',
    headerName: 'Last Modified',
    width: 150,
    
     valueGetter: (params) =>
     // moment ago
      `${moment(params.row.modified_at).fromNow()}`,
  },

  {
    field: 'Options',
    headerName: 'Options',
    width: 70,
    sortable: false,
    renderCell: (params) =>{
      return <Link to={params.row.url} state = {params.row} className="TableRowLink"><FiEdit className="EditIcon"/></Link>
    },
  },
];


const Saved = (props) => {

    const outletProps = useOutletContext()
    console.log(outletProps)
  return (
    <div className="CreatePage">   
        <div className="CreatePageTitle">
            Recent Docs
        </div>
        <div className="SavedPageTable">
            <DataGrid
                rows={outletProps.files}
                columns={columns}
                pageSize={20}
                rowsPerPageOptions={[20]}
                autoHeight
                autoWidth
                getRowId={(row) => row.documentId}
                className="SavedPageDataGrid"
            />
        </div>

    </div>
  );
};

export default Saved;