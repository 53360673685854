import React, { useState, useRef } from 'react';
import { useOutletContext, BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import axios from 'axios';
import { auth } from "../../firebase";
import { DataGrid } from '@mui/x-data-grid';
import "./data.css"

const columns = [
      { field: 'id', headerName: 'ID', width: 70, hidden: true },
    //   { field: 'documentName', headerName: 'File Name', width: 250, },
      { field: 'score', headerName: 'score', width: 200, },
      {
        field: 'metadata',
        headerName: 'Preview',
        width: 200,
        sortable: false,
         valueGetter: (params) =>
          `${params.row.metadata?.profile_info && params.row.metadata?.profile_info}`,
        flex: 1
      },
      {
        field: 'id2',
        headerName: 'view profile',
        width: 70,
        sortable: false,
        renderCell: (params) =>{
          return <a href = {"https://mustardjobs.vincere.io/candidateDashboard.do?tabId=1&id="+String(params.row.id)} className="TableRowLink" target="_blank">View Profile</a>
        },
      },
    ];
    

const Search = (props) => {
    const [searchInput, setSearchInput] =useState("")
    const [searchResult, setSearchResult] =useState({})
    const retryRef = useRef(0)

    const handleChange = (event) => {
        switch (event.target.id) {
          case "DataSearch":
            setSearchInput(event.target.value);
            break;
        }
      };

      const handleSearch = async() => {
        console.log("searching")
        try {
            let token = outletProps?.user?.stsTokenManager?.accessToken
            if (outletProps?.user?.stsTokenManager?.accessToken) {
              if (outletProps?.user.stsTokenManager.expirationTime < Date.now()) {
                console.log("refreshing token")
                outletProps?.user.getIdToken({forceRefresh: true}).then(token => {
                    handleSearch()
                })
                return
              }
            }
            const data = {
                "search": searchInput,
            }
            const searchData =  await axios.post(outletProps?.backend_url+"/vincere-search", {data},   {headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + token,
            }},)
            retryRef.current = 0
            if(searchData.status === 200) {
                console.log(searchData.data)
                searchData.data?.matches.forEach((item, index) => {
                    item.id2 = item.id
                    item.id = parseInt(item.id)
                })
                setSearchResult(searchData.data?.matches)
            }
          } catch (err) {
            retryRef.current += 1
            if (retryRef.current > 4) return
            console.error(err);
            if (err?.response?.status==403) {
              if (err?.response?.data?.code === "auth/id-token-expired") {
                console.log("token expired")
                auth.currentUser.getIdToken({forceRefresh: true}).then(token => {
                  //try again
                  handleSearch()
                })
              }
            }
          }
      }

    const outletProps = useOutletContext()
    console.log(searchResult)
  return (
    <div className="CreatePage">   
        <div className="CreatePageTitle">
            Search Data
        </div>
        <div className="CreatePageContent">
            <div className="SearchPage">
                <div className = "DataSearchBar">
                    <TextField label={null}
                        multiline = {false}
                        id={"DataSearch"}
                        onChange={handleChange}
                        rows={1}
                        value={searchInput}
                        // onClick={setShowTooltip}
                        // onBlur={setHideTooltip}
                    />
                </div>
                <Button className="LoginButton" variant="contained" onClick={handleSearch}>Search</Button>
                <div className="SavedPageTable">
                <DataGrid
                    rows={searchResult}
                    columns={columns}
                    pageSize={20}
                    rowsPerPageOptions={[20]}
                    autoHeight
                    autoWidth
                    getRowId={(row) => row.id}
                    className="SavedPageDataGrid"
                />
            </div>
            </div>

        </div>
    </div>
  );
};

export default Search;