import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useLocation, useOutletContext } from "react-router-dom";
import Button from '@mui/material/Button';

const SuccessDisplay = ({ sessionId, outletProps }) => {
    return (
      <section>
        <div className="product Box-root">
          <div className="description Box-root">
            <h3>Subscription successful!</h3>
          </div>
        </div>
        {/* <form action={outletProps.backend_url+"/create-portal-session"} method="POST">
          <input
            type="hidden"
            id="session-id"
            name="session_id"
            value={sessionId}
          />
          <button id="checkout-and-portal-button" type="submit">
            Manage your billing information
          </button>
        </form> */}
        <Link to="/create" className="BreadcrumbText BreadcrumbLink" style={{textDecoration: "underline"}}>Start making the most of Taylr</Link>
      </section>
    );
  };
  
  const Message = ({ message }) => (
    <section>
      <p>{message}</p>
        <Link to="/settings/plans"  className="BreadcrumbText BreadcrumbLink" style={{textDecoration: "underline"}}>
            Back to Plans
        </Link>
    </section>
  );
  


function Stripe() {
    const outletProps = useOutletContext()
    let [message, setMessage] = useState('');
    let [success, setSuccess] = useState(false);
    let [sessionId, setSessionId] = useState('');
  
    useEffect(() => {
      // Check to see if this is a redirect back from Checkout
      const query = new URLSearchParams(window.location.search);
  
      if (query.get('success')) {
        setSuccess(true);
        setSessionId(query.get('session_id'));
      }
  
      if (query.get('canceled')) {
        setSuccess(false);
        setMessage(
          "Order canceled -- continue to shop around and checkout when you're ready."
        );
      }
    }, [sessionId]);
  
    let content
    if (success && sessionId !== '') {
        content =  <SuccessDisplay sessionId={sessionId} outletProps={outletProps}/>;
    } else {
        content =  <Message message={message} outletProps={outletProps}/>;
    }


  return (
    <div className="CreatePage">   
        <div className="CreatePageTitle">
            Your Settings
        </div>
        <div className="SettingsPage">
            <div className="SettingsPageSection">
                {content}

            </div>
        </div>

    </div>
  );
}

export default Stripe;