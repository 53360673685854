import React, { useState } from 'react';
import CreateTemplateComponent from './CreateTemplateComponent';
import BooleanSearch from './BooleanSearch2';
const BooleanSearchComponent = (props) => {

  return (
    <div className="CreatePage">   
        <div className="CreatePageTitle">
          Write a Boolean Search Query
        </div>
        <BooleanSearch />
    </div>
  );
};

export default BooleanSearchComponent;