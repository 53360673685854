import React, { useEffect } from 'react';
import { useNavigate, useOutletContext, BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";
import './create/create.css'



const Main = () => {

  const navigate = useNavigate();

  useEffect(() => {
    navigate('/create')
  }, [])

  return (

    <div className="CreatePage">   
    <div className="CreatePageTitle">
        Your Dashboard
    </div>
    <div className="CreatePageGrid">
        <Link to="/create" className="CreateBox">
            <div className="CreateBoxLogo">
                ✏️
            </div>
            <div className="CreateBoxText">
                Start Writing
            </div>
            <div className="CreateBoxSubtitle">
                Get started by getting Taylr to write something for you!
            </div>
        </Link>
        
    </div>

</div>
  );
};

export default Main;