import React, { useState } from 'react';
import CreateTemplateComponent from './CreateTemplateComponent';

const JobDescriptionInputTemplate = {
  "candidate_name": {rows: 1, multiline: false, label: "Candidate Name", tooltip: "The candidate name. Can also be a variable (ie. #CandidateFirstName#) for email automation systems", value: ""},
  "reason": {rows: 2, multiline: true, label: "Reason for Contacting", tooltip: "ie. I just got off the phone with my client and have an exciting new role", tooltip_items: [], value: ""},
  "job_summary": {rows: "auto", multiline: true, label: "Job Summary", tooltip: "Add some information about the role, such as:", tooltip_items: ["Position/Title", "Location", "Experience", "Specific skills", "Software skills", "Any particular projects to highlight"], value: "", limit: 500},
  "candidate_experience": {rows: "auto", multiline: true, label: "Candidate experience, why do you think they are a good fit?", tooltip: "Add some information about the candidate and why you think they could be a good fit:", tooltip_items: ["Position/Title", "Location", "Specific Skills/Software", "Project/Sector experience",], value: "", limit: 500},
}

const EmailPitchJob = (props) => {

  return (
    <div className="CreatePage">   
        <div className="CreatePageTitle">
          Pitch a Job to a Candidate
        </div>
        <CreateTemplateComponent inputTemplate={JobDescriptionInputTemplate} url={"/email-pitch-job"} refine_output={true}/>
    </div>
  );
};

export default EmailPitchJob;