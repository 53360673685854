import React from 'react';
import { useOutletContext, BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";
import Button from '@mui/material/Button';

const vincere_url = "https://id.vincere.io/oauth2/authorize?response_type=code&client_id=9373b99b-d885-4881-812c-946771ebce78&redirect_uri=https://app.taylr.ai/callback&state=Vincere"

const DataLink = (props) => {

    const outletProps = useOutletContext()
    console.log(outletProps)
  return (
    <div className="CreatePage">   
        <div className="CreatePageTitle">
            Link your account
        </div>
        <div className="CreatePageContent">
            <a href={vincere_url} className="LoginButtonContainer" target="_blank" rel="noreferrer">
                <Button className="LoginButton" variant="contained">Link with Vincere</Button>
            </a>
        </div>
    </div>
  );
};

export default DataLink;