import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { PageviewTracker } from './GoogleAnalytics';
import Login from "./pages/Login";
import Register from "./pages/Register";
import Reset from "./pages/Reset";
import Dashboard from "./pages/Dashboard";
import Settings from "./pages/Settings";

import Create from "./dashboard_pages/create/Create";
import JobDescription from "./dashboard_pages/create/JobDescription";
import AnonJob from "./dashboard_pages/create/AnonJob";
import LinkedinPostIdeas from "./dashboard_pages/create/LinkedinPostIdeas";
import LinkedinPost from "./dashboard_pages/create/LinkedinPost";
import LinkedinJobAd from './dashboard_pages/create/LinkedinJobAd';
import EmailPitchCandidate from './dashboard_pages/create/EmailPitchCandidate';
import LinkedinPoll from './dashboard_pages/create/LinkedinPoll';
import EmailPitchJob from './dashboard_pages/create/EmailPitchJob';
import BooleanSearch from "./dashboard_pages/create/BooleanSearch";


import DataLink from "./dashboard_pages/data/DataLink";
import Callback from "./dashboard_pages/data/Callback";
import Search from "./dashboard_pages/data/Search";

import Main from "./dashboard_pages/Main";

import Help from "./dashboard_pages/help/Help";

import Profile from "./settings_pages/Profile";
import Plans from "./settings_pages/Plans";
import Stripe from "./settings_pages/Stripe"

import Saved from "./dashboard_pages/saved/Saved";
import "./App.css";

function App() {
  return (
    <div className="app">
      <Router>
        <PageviewTracker />
        <Routes>
          <Route exact path="/login" element={<Login />} />
          <Route exact path="/register" element={<Register />} />
          <Route exact path="/reset" element={<Reset />} />
          <Route exact path="/" element={<Dashboard />} >
            <Route index element={<Main />} />
          </Route>
          <Route exact path="/create" element={<Dashboard />}>
            <Route index element={<Create />} />
            <Route exact path="job-post" element={<JobDescription />} />
            <Route exact path="agencify" element={<AnonJob />} />
            <Route exact path="linkedin-post-ideas" element={<LinkedinPostIdeas />} />
            <Route exact path="linkedin-post" element={<LinkedinPost />} />
            <Route exact path="linkedin-job-ad" element={<LinkedinJobAd />} />
            <Route exact path="linkedin-poll" element={<LinkedinPoll />} />
            <Route exact path="email-pitch-candidate" element={<EmailPitchCandidate />} />
            <Route exact path="email-pitch-job" element={<EmailPitchJob />} />
            <Route exact path="boolean-search" element={<BooleanSearch />} />
            
          </Route>
          <Route exact path="/callback" element={<Dashboard />}>
            <Route index element={<Callback />} />           
          </Route>
          <Route exact path="/data" element={<Dashboard />}>
            <Route index element={<DataLink />} />
            <Route exact path="test" element={<Search />} />
                 
          </Route>
          <Route exact path="/history" element={<Dashboard />} >
            <Route index element={<Saved />} />
          </Route>

          <Route exact path="/settings" element={<Dashboard />} >
            <Route index element={<Profile />} />
            <Route exact path="plans" element={<Plans />} />
            <Route exact path="stripe" element={<Stripe />} />
          </Route>

          <Route exact path="/help" element={<Dashboard />} >
            <Route index element={<Help />} />
          </Route>

        </Routes>
      </Router>
    </div>
  );
}

export default App;
