import { initializeApp } from "firebase/app";
import {
  GoogleAuthProvider,
  getAuth,
  signInWithPopup,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  sendEmailVerification,
  updateProfile,
  signOut,
} from "firebase/auth";
import {
  getFirestore,
  query,
  getDocs,
  collection,
  where,
  addDoc,
} from "firebase/firestore";
import { store } from './index';

const firebaseConfig = {
  apiKey: "AIzaSyDYHmicMCrteiMjYqOB_F1WFp61EHywUn4",
  authDomain: "taylr-15118.firebaseapp.com",
  projectId: "taylr-15118",
  storageBucket: "taylr-15118.appspot.com",
  messagingSenderId: "493457712704",
  appId: "1:493457712704:web:e20947648ac3ff8ca021a5",
  measurementId: "G-NW21Q8G576"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);

const googleProvider = new GoogleAuthProvider();

auth.onAuthStateChanged(function(user) {
  if (user) {
    // User is signed in.
    console.log("Signed in", user)
    let forceRefresh = false
    if (user.emailVerified == false) {
      console.log("email unverified, refreshing token & sending email")
      forceRefresh = true
      // sendEmailVerification(user)
    }
    user.getIdToken(forceRefresh).then(function(idToken) {
      // Dispatch the token to the Redux store.
      store.dispatch({
        type: 'SET_TOKEN',
        payload: idToken
      });
      console.log("Dispatched token")
    }).catch(function(error) {
      console.error(error);
    });
    
  } else {
    // User is not signed in.
  }
});

const signInWithGoogle = async () => {
  try {
    const res = await signInWithPopup(auth, googleProvider);
    const user = res.user;
    const q = query(collection(db, "users"), where("uid", "==", user.uid));
    const docs = await getDocs(q);
    if (docs.docs.length === 0) {
      await addDoc(collection(db, "users"), {
        uid: user.uid,
        name: user.displayName,
        authProvider: "google",
        email: user.email,
      });
    }
  } catch (err) {
    console.error(err);
    // alert(err.message);
  }
};

const logInWithEmailAndPassword = async (email, password) => {
  try {
    await signInWithEmailAndPassword(auth, email, password);
  } catch (err) {
    console.error(err);
    // alert(err.message);
  }
};

const registerWithEmailAndPassword = async (name, email, password) => {
  try {
    const res = await createUserWithEmailAndPassword(auth, email, password);
    const user = res.user;
    await updateProfile(user, {displayName: name})
    await addDoc(collection(db, "users"), {
      uid: user.uid,
      name,
      authProvider: "local",
      email,
    });
    await sendEmailVerification(user)
  } catch (err) {
    console.error(err);
    console.log(err.message)
    // alert(err.message);
  }
};

const sendPasswordReset = async (email) => {
  try {
    await sendPasswordResetEmail(auth, email);
    alert("Password reset link sent!");
  } catch (err) {
    console.error(err);
    // alert(err.message);
  }
};

const logout = () => {
  signOut(auth);
};

export {
  auth,
  db,
  signInWithGoogle,
  logInWithEmailAndPassword,
  registerWithEmailAndPassword,
  sendPasswordReset,
  logout,
};
