import React, { useState } from 'react';
import CreateTemplateComponent from './CreateTemplateComponent';


const JobDescriptionInputTemplate = {
  "template": {type: "job_description_template", label:[<span>Template<span className="Optional">Optional</span></span>], tooltip:"Want more consistent results, or a specific writing style? Add an example and Taylr will try to replicate it.", value: ""},
  "job_title": {rows: 1, multiline: false, label: "Job Title", tooltip: "What is the Job Title for this role?", value: ""},
  "location": {rows: 1, multiline: false, label: "Location", tooltip: "Where is the role located?", value: ""},
  "about_company": {rows: 3, multiline: true, label: "About the Company", tooltip: "For the best results, mention at least 3 to 4 points such as:", tooltip_items: ["Company name (optional)","Location", "Sectors/Industry they cover", "What they work on", "Awards", "Sustainability/Diversity"], value: ""},
  "about_role": {rows: 3, multiline: true, label: "About the Role", tooltip: "For the best results, mention at least 3 to 4 points such as:", tooltip_items: ["What they will work on?", "Will they lead a team?", "Who they will work with?", "What their day to day looks like", "Will they have to deal with clients/suppliers?"], value: ""},
  "requirements": {rows: 3, multiline: true, label: "Requirements & Qualifications", tooltip: "For the best results, mention at least 3 to 4 points such as:", tooltip_items: ["Qualifications", "Years of Experience", "Software Knowledge", "Soft skills: communication, problem solving, leadership...", "Specific sector or project experience"], value: ""},
  "benefits": {rows: 3, multiline: true, label: "Benefits", tooltip: "For the best results, mention at least 3 to 4 points such as:", tooltip_items: ["Flexible working", "Salary information", "Holiday allowance", "Pension", "Private medical insurance"], value: ""},
  "how_to_apply": {rows: 2, multiline: true, label: "How to Apply", tooltip: "Detail how to apply such as:", tooltip_items: ["Who to contact - name, email address and phone number", "What attachments are needed i.e. CV, Portfolio"], value: ""},
  "agency": {store: true, type: "checkbox", default: true, label: "Agency Recruiter", tooltip: "Taylr will write this from a recruitment agency's perspective if checked", tooltip_items: [], value: true},
}

const JobDescription = (props) => {


  return (
    <div className="CreatePage">   
        <div className="CreatePageTitle">
          Create a Job Advert
        </div>
        <CreateTemplateComponent inputTemplate={JobDescriptionInputTemplate} url={"/generate_job_description"} refine_output={true}/>
    </div>
  );
};

export default JobDescription;