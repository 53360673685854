import React, { useState, useRef } from 'react';
import { TextField, Select, FormControl, InputLabel, MenuItem, Button } from '@mui/material';
import { FiEdit, FiCheck } from 'react-icons/fi';

const DocumentName = (props) => {
  const [disableButton,setDisableButton] = useState(true)
  const inputRef = useRef();

  const handleEdit = () => {
    // code to handle document name editing
    setDisableButton(false)
    setTimeout(() => {
      inputRef.current.focus();
    }, 10);
  };

  const handleEditFinish = () => {
    // code to handle document name editing
    setDisableButton(true)
    
  };

  const keyPress = (e) =>{
    if(e.keyCode == 13){
       console.log('value', e.target.value);
       handleEditFinish()
    }
  }


  const handleChange = (event) => {
    props.setDocumentName(event.target.value);
    props.documentNameRef.current = event.target.value
  };

  return (
    <div className="FileNameContainer">
        <TextField variant="outlined" className="fileNameInput" disabled = {disableButton} value={props.documentName} inputRef={inputRef} onChange={handleChange} onKeyDown={keyPress}/>
        {/* <div className="FileListDropdown">
          {documents.map((doc, index) => (
            <MenuItem key={index} value={doc}>{doc}</MenuItem>
          ))}
        </div> */}
        {disableButton && <FiEdit className="editIcon" onClick={handleEdit} />}
        {!disableButton && <FiCheck className="editFinishIcon" onClick={handleEditFinish} />}
    </div>
  );
};

export default DocumentName;