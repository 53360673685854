import React, { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { auth, logInWithEmailAndPassword, signInWithGoogle } from "../firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import "./Login.css";
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { FcGoogle } from 'react-icons/fc';
import Starscape from '../components/Starscape';

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [user, loading, error] = useAuthState(auth);
  const navigate = useNavigate();
  const location = useLocation()

  useEffect(() => {
    if (loading) {
      // maybe trigger a loading screen
      return;
    }
    if (user) {
      if (location.search){
        //get url params
        const params = new URLSearchParams(location.search);
        //get redirect url
        const redirect = params.get('redirect');
        //redirect to url
        navigate("/settings/"+redirect)
      } else {
        navigate("/")
      }
    };
  }, [user, loading]);

  const redirect = () => {
    console.log(location)
    if (location.search){
      navigate({
        pathname: "/register",
        search: location.search
      })
    } else {
      navigate("/register")
    }
    
  }

  return (
    <div className="login">
      <div className="LoginNavBar">
        <div className="dashboard-logo">
          <img src="taylrlogo.png" className="TaylrLogo" alt="Taylr Logo"/>
          TAYLR.<span className="dashboard-logo-ai">AI</span>
        </div>    
      </div>
      <div className="loginContent">
        <div className="loginMessage">Welcome Back</div>
        <div className="LoginButtons">
          <div className="LoginButtonContainer">
            <Button onClick={signInWithGoogle} className="LoginWithGoogleButton" variant="contained"><FcGoogle className="GoogleIcon"/> Login with Google</Button>
          </div>
        </div>
        <div className="LoginAlternative">Or login with email</div>
        <div className="LoginBox">
          <TextField
            type="email"
            id="email"
            className="login__textBox"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Your Email Address"
            label={null}
          />
          <TextField
            type="password"
            id="password"
            className="login__textBox"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Password"
            label={null}
          />
          <div className="LoginButtonContainer">
            <Button onClick={() => logInWithEmailAndPassword(email, password)} className="LoginButton" variant="contained">Login</Button>
          </div>
          <div className="RegisterDiv">
            Don't have an account? <span onClick={redirect} className="Link YellowLink">Register</span> now.
          </div>
          <Link to="/reset" className="ForgotPassword">Forgot Password</Link>
        </div>
      </div>
      <Starscape />
    </div>
  );
}

export default Login;
