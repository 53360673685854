import React, { useEffect } from 'react';
import { useOutletContext, BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";
import axios from 'axios'

const Callback = (props) => {

    const outletProps = useOutletContext()
    console.log(outletProps)

    useEffect(async() => {
      if (outletProps.user?.stsTokenManager?.accessToken) {
        const token = outletProps.user.stsTokenManager.accessToken
        //get code from url params
        const urlParams = new URLSearchParams(window.location.search);
        const code = urlParams.get('code');
        console.log(code)
        const data = {code} 
        const register =  await axios.post(outletProps.backend_url+"/vincere-auth", {data},   {headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        }},)
        console.log(register)
      }
    }, [outletProps.user])


  return (
    <div className="CreatePage">   
        <div className="CreatePageTitle">
            Linking your account with Vincere
        </div>
    </div>
  );
};

export default Callback;