import React, { useEffect, useState, useRef } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import "./Dashboard.css";
import { auth, db, logout } from "../firebase";
import { query, collection, getDocs, where } from "firebase/firestore";
import { useSelector } from 'react-redux';
import axios from 'axios'
import DashboardLayout from "../components/DashboardLayout";
import { Routes, Route, Outlet  } from "react-router-dom";


let backend_url = process.env.BACKEND_URL || `https://api.taylr.ai` //`http://localhost:5000`;//`https://api.taylr.ai`

if (process.env.NODE_ENV == "production"){
  backend_url = `https://api.taylr.ai`
}

function Settings(props) {
  const [user, loading, error] = useAuthState(auth);
  const [userData, setUserData] = useState({});

  // const token = useSelector(user => {console.log(user); return user.auth.token} );
  const navigate = useNavigate();

  const fetchUserInfo = async (token) => {
    try {
      const response = await axios.get(backend_url+"/user_info",   {headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      }},)

      setUserData(response.data);
    } catch (err) {
      console.error(err);
      // console.log(err.stack)
      // console.log(err?.response?.data)
      if (err?.response?.status==403) {
        if (err?.response?.data?.code === "auth/id-token-expired") {
          console.log("token expired")
          auth.currentUser.getIdToken({forceRefresh: true}).then(token => {
            fetchUserInfo(token)
          })
        }
      }
      // alert("An error occured while fetching user data");
    }
  };


  useEffect(() => {
    if (user?.stsTokenManager?.accessToken) {
      if (user.stsTokenManager.expirationTime < Date.now()) {
        console.log("refreshing token")
        user.getIdToken({forceRefresh: true})
      } else {
        console.log("fetching user info")
        fetchUserInfo(user.stsTokenManager.accessToken);
      }
    }
  }, [user]);

  useEffect(() => {
    if (loading) return;
    if (!user) return navigate("/login");
  }, [user, loading]);

  return (
    <DashboardLayout userData={userData} user={user} logout = {logout}>
      <Outlet context={{user, userData, logout, backend_url}}/>
    </DashboardLayout>
  );
}

export default Settings;
