import React from 'react';
import { useOutletContext, BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";

const Help = (props) => {

    const outletProps = useOutletContext()
    console.log(outletProps)
  return (
    <div className="CreatePage">   
        <div className="CreatePageTitle">
            Need Help?
        </div>
        <div className="SettingsPage">
            <div className="SettingsPageSection">
                Please feel free to reach out to us at any time - You can reach us at <a href="mailto:aylin@taylr.ai" target="_blank" className="Link">aylin@taylr.ai</a>
            </div>
        </div>


    </div>
  );
};

export default Help;