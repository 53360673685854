import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { auth, sendPasswordReset } from "../firebase";
import "./Reset.css";
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { FcGoogle } from 'react-icons/fc';
import Starscape from '../components/Starscape';

function Reset() {
  const [email, setEmail] = useState("");
  const [user, loading, error] = useAuthState(auth);
  const navigate = useNavigate();

  useEffect(() => {
    if (loading) return;
    if (user) navigate("/");
  }, [user, loading]);

  return (
    <div className="login">
      <div className="LoginNavBar">
        <div className="dashboard-logo">
          <img src="taylrlogo.png" className="TaylrLogo" alt="Taylr Logo"/>
          TAYLR.<span className="dashboard-logo-ai">AI</span>
        </div>    
      </div>
      <div className="loginContent">
        <div className="loginMessage">Forgotten your Password?</div>
        <div className="LoginBox">
          <TextField
            type="email"
            id="email"
            className="login__textBox"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Your Email Address"
            label={null}
          />
          <div className="LoginButtonContainer">
            <Button onClick={() => sendPasswordReset(email)} className="LoginButton" variant="contained">Send Password Reset Email</Button>
          </div>
          <div className="RegisterDiv">
            Don't have an account? <Link to="/register" className="Link YellowLink">Register</Link> now.
          </div>
        </div>
      </div>
      <Starscape />
    </div>
  );
}

export default Reset;
