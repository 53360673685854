import React, { useEffect, useState, useRef } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { useOutletContext, useLocation, useNavigate, BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";
import StreamData from '../../functions/StreamData';
import { MdContentCopy }from 'react-icons/md';
import Tooltip from '@mui/material/Tooltip';
import { Checkbox } from '@mui/material';
import { loc_lookups } from '../../components/DashboardLayout';
import { v4 as uuidv4 } from 'uuid';
import JobDescriptionTemplate from '../../components/JobDescriptionTemplate';
import { BsLinkedin } from 'react-icons/bs'
import { BiRefresh, BiUndo, BiRedo } from 'react-icons/bi'
import Collapse from '@mui/material/Collapse';
import { connectFirestoreEmulator } from 'firebase/firestore';

const defaultRefineExamples = ["Make it shorter", "Add more bullet points", "Rewrite in a friendly, conversational tone"]

const CreateTemplateComponent = (props) => {
  const outletProps = useOutletContext()

  const [jobInput, setjobInput] = useState(props.inputTemplate);
  const [response, setResponse] = useState('');
  const [instructions, setInstructions] = useState('');
  const [streaming, setStreaming] = useState(false);
  const [copied, setCopied] = useState(false);
  const [responded, setResponded] = useState(false);
  const [renderCount, setRenderCount] = useState(1);
  const documentId = useRef() 
  const responseRef = useRef()
  const location = useLocation()
  const navigate = useNavigate()
  const jobInputRef = useRef({})

  const historyRef = useRef([])
  const futureRef = useRef([])

  const handleChange = (event) => {
    switch (event.target.id) {
      case "responseInput":
        responseRef.current = event.target.value
        setResponse(event.target.value);
        break;
      case "refineOutput":
        setInstructions(event.target.value);
        break;
      default:
        let value = event.target.value;
        if (event.target.type === "checkbox"){
          value = event.target.checked;
        }
        if (props.inputTemplate[event.target.id].store){
          localStorage.setItem(event.target.id, value)
        }
        setjobInput(currentInput => {return {...currentInput, [event.target.id]: {...currentInput[event.target.id], value: value}}});
        jobInputRef.current = {...jobInputRef.current, [event.target.id]: {...jobInputRef.current[event.target.id], value: value}}
        break;
    }
  };

  // useEffect(() => {
  //   if (streaming ==false){
  //     console.log("Stream ended")
  //     console.log(response)
  //   }
  // }, streaming)

  const saveData = () => {
    console.log("saving data")
    console.log(responseRef.current)
    if (responseRef.current){
      let type = location.pathname.split("/")[2]
      if (loc_lookups[type]){
        type = loc_lookups[type]
      }
      let inputValues = {}
      for (const [key, value] of Object.entries(jobInputRef.current)) {
        inputValues[key] = value.value
      }
      if (!documentId.current){
        documentId.current = uuidv4()
      }
      outletProps.saveData({input: JSON.stringify(inputValues), response: responseRef.current, documentId: documentId.current, documentName: outletProps.documentNameRef.current, type, url: location.pathname})
    }
  }

  const handleSubmit = async (event) => {
    console.log(event)
    try {
      if (outletProps?.user?.emailVerified === false){
        alert("Please verify your email address before using Taylr.")
        return
      }
      const input = {prompt: {}}
      if (event.target.id == "RefineButton") {
        input.messages = []
        if (responseRef.current) {
          input.messages.push({role: "assistant", content: responseRef.current})
        }
        if (instructions) {
          input.messages.push({role: "user", content: instructions})
        }
        setInstructions("")
      }

      
      for (const [key, value] of Object.entries(jobInput)) {
        input.prompt[key] = {value: value.value, label: value.label}
      }
      console.log("Calling StreamData")
      const url = outletProps.backend_url+props.url
      console.log("Getting Data: ", input)
      if (!documentId.current){
        documentId.current = uuidv4()
      }
      input.documentId =  documentId.current
      await StreamData(setResponse, setStreaming, outletProps.setCredits, input, outletProps.user, url, props.outputStart, responseRef)//"http://localhost:5000/generate_job_description")
      console.log("StreamData returned")
      historyRef.current.push(responseRef.current)
      futureRef.current = []
      setResponded(true)
      //save the data
      saveData()
    } catch (err) {
      setStreaming(false)
      console.error(err);
    }
  };

  const handleCopy = async () => {
    navigator.clipboard.writeText(response)
    setCopied(true)
    setTimeout(() => {
      setCopied(false)
    }, 2000);
  };

  const updateTemplate = (template) => {
    let id = "template"
    setjobInput(currentInput => {return {...currentInput, [id]: {...currentInput[id], value: template}}});
  }

  const handleLinkedInNavigate = () => {
    const myState = { job_description: response };
    navigate('/create/linkedin-job-ad', { state: myState });
  };

  const handleUndo = () => {
    console.log("Handle Undo: ", historyRef.current.length)
    if (historyRef.current.length > 0){
      futureRef.current.push(responseRef.current)
      const newResponse = historyRef.current.pop()
      responseRef.current = newResponse
      setResponse(newResponse)
    } else {
      setRenderCount(renderCount => renderCount + 1)
    }
  }

  const handleRedo = () => {
    console.log("Handle Redo: ", futureRef.current.length)
    if (futureRef.current.length > 0){
      historyRef.current.push(responseRef.current)
      const newResponse = futureRef.current.pop()
      responseRef.current = newResponse
      setResponse(newResponse)
    } else {
      setRenderCount(renderCount => renderCount + 1)
    }
  }

  const getLength = (input, key) => {
    //get width of element id (key)
    const element = document.getElementById(key);
    if (element){
      const width = element.offsetWidth;
      const letters_in_row = Math.floor((width-30)/8)
      //get number of rows
      let rows = input.split("\n")
      let total_rows = 1
      for (let i = 0; i < rows.length; i++){
        total_rows += Math.ceil((rows[i].length+1)/letters_in_row)
      }
      return total_rows
    } else {
      return 0
    }

  }

  useEffect(() => {
    const handleUndoRedo = (event) => {
      if (event.ctrlKey && event.keyCode === 90) {
        event.preventDefault();
        handleUndo();
      }
      if (event.ctrlKey && event.keyCode === 89) {
        event.preventDefault();
        handleRedo();
      }
    };

    document.addEventListener('keydown', handleUndoRedo);

    return () => {
      document.removeEventListener('keydown', handleUndoRedo);
    };
  }, [handleUndo, handleRedo]);

  useEffect(() => {
    console.log("Mounted, location: ", location)
    let inputValues = {}
    setResponse("")
    if (location.state && location.state.input){
      inputValues = JSON.parse(location.state.input)
      documentId.current = location.state.documentId
      console.log("Setting document name:")
      outletProps.setDocumentName(location.state.documentName)
      outletProps.documentNameRef.current = location.state.documentName
      responseRef.current = location.state.response
      setResponse(location.state.response)
      historyRef.current.push(location.state.response)
    } else {
      let new_document_name = "New Document"
      const loc = location.pathname.split("/")[2]
      if (loc_lookups[loc]){
        new_document_name = loc_lookups[loc]
      }
      //add full date to new document name
      new_document_name += " " + new Date().toLocaleDateString("en-GB", {year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric'})
      outletProps.setDocumentName(new_document_name)
      outletProps.documentNameRef.current = new_document_name
      //creating a new document - generate an internal id?
      documentId.current = uuidv4()
    }
    for (const key in props.inputTemplate){
      const currentInput = {...jobInput}
      let update = false
      if (inputValues[key]){
        currentInput[key].value = inputValues[key]
        update = true
      } else if (location.state && location.state[key]){
        currentInput[key].value = location.state[key]
        update = true
      } else if (props.inputTemplate[key].store){
         let prevValue = localStorage.getItem(key)
         if (prevValue){
          update = true
          if (currentInput[key].type == "checkbox"){
            prevValue = JSON.parse(prevValue)
          }
          currentInput[key].value = prevValue
         }
      }

      if (update){
        setjobInput(currentInput)
        jobInputRef.current = currentInput
      }
    }
    return () => {
      //save data on unmount
      saveData()
    }
  }, [])

  let CreatePageInputContainerStyle = {}
  if (props.flex == false){
    CreatePageInputContainerStyle = {flex: "0"}
  }
  let outputRows = "auto"
  if (props.outputRows){
    outputRows = props.outputRows
  }
  let refineOutputOptions = defaultRefineExamples
  if (props.refine_examples){
    refineOutputOptions = props.refine_examples
  }
  console.log("render")
  return (
        <div className="CreatePageContent">
          <div className="CreatePageInput">
            <div className="CreatePageInputContainer" style={CreatePageInputContainerStyle}>
              {Object.entries(jobInput).map(([key, value]) => {
                if (value.type == "checkbox"){
                  return (
                    <div className="CreatePageOutputCheckbox">
                      <div className="CreatePageInputLabel">
                        {value.label}
                      </div>
                      {value.tooltip ? <Tooltip arrow classes={{ popper: "TaylrTooltip" }} title={<div className="TooltipContents">{value.tooltip}{value.tooltip_items && value.tooltip_items.length > 0 && <ul>{value.tooltip_items.map(item => <li>{item}</li>)}</ul>}</div>} placement="right" open={jobInput.showTooltip}>
                          <Checkbox
                            checked={value.value}
                            onChange={handleChange}
                            inputProps={{ 'aria-label': 'controlled' }}
                            id={key}
                          />
                        </Tooltip>:
                        <Checkbox
                        checked={value.value}
                        onChange={handleChange}
                        inputProps={{ 'aria-label': 'controlled' }}
                        id={key}
                      />}

                    </div>
                  )
                }
                if (value.type == "job_description_template"){
                  return (
                      <div className="CreatePageOutput">
                        <div className="CreatePageInputLabel">
                          {value.label}
                        </div>
                        <JobDescriptionTemplate tooltip={value.tooltip} updateTemplate = {updateTemplate} saveJobDescriptionTemplates = {outletProps.saveJobDescriptionTemplates} jobDescriptionTemplates={outletProps.jobDescriptionTemplates} backend_url = {outletProps.backend_url} user = {outletProps.user} />
                      </div>
                  )
                }

                return (
                <div className={"CreatePageOutput CreatePageOutput"+String(value.rows)}>
                  <div className="CreatePageInputLabel">
                    {value.label}
                  </div>
                  {value.tooltip ? <Tooltip arrow classes={{ popper: "TaylrTooltip" }} title={<div className="TooltipContents">{value.tooltip}{value.tooltip_items && value.tooltip_items.length > 0 && <ul>{value.tooltip_items.map(item => <li>{item}</li>)}</ul>}</div>} placement="right" open={jobInput.showTooltip}>
                    <TextField label={null}
                      multiline = {value.multiline}
                      id={key}
                      onChange={handleChange}
                      rows={Math.max(value.rows, getLength(jobInput[key].value, key))}
                      value={jobInput[key].value}
                      // onClick={setShowTooltip}
                      // onBlur={setHideTooltip}
                    />
                  </Tooltip>:
                  <TextField label={null}
                      multiline = {value.multiline}
                      id={key}
                      onChange={handleChange}
                      rows={Math.max(value.rows, jobInput[key].value.length/20)}
                      value={jobInput[key].value}
                    />}
                </div>)
              })}

            </div>
            <div className="CreatePageButtonContainer">
                <Button variant="contained" color="primary" onClick={handleSubmit} disabled={streaming} className="SubmitButton">
                  Submit
                </Button>
              </div>
          </div>
          <div className="CreatePageInput">
            <div className="CreatePageOutput CreatePageOutputauto" style={{flex: outputRows == "auto" ? "1 1 auto" : "0"}}>
              <div className="CreatePageInputLabel">
                Taylr's Output
                <div className="CopyButtonContainer">
                  {(jobInput.template && (response && response.length > 0)) &&  <Tooltip arrow classes={{ popper: "TaylrTooltip" }} title={<div className="TooltipContents">Turn this into a LinkedIn Post</div>} placement="top">
                    <Button variant="contained" color="primary" onClick={handleLinkedInNavigate}  className="LinkedInButton">
                      <div className="LinkedInButtonLogoContainer">
                        <BsLinkedin className="LinkedInLogo LinkedInButtonLogo" />
                      </div>
                    </Button>
                  </Tooltip>}
                  <Tooltip arrow classes={{ popper: "TaylrTooltip" }} title={<div className="TooltipContents">Undo</div>} placement="top">
                    <span>
                      <Button variant="contained" color="primary" onClick={handleUndo} disabled={!(historyRef.current && historyRef.current.length > 0)  || streaming} className="SubmitButtonSmall">
                        <BiUndo className="CopyIcon"/>
                      </Button>
                    </span>
                  </Tooltip>
                  <Tooltip arrow classes={{ popper: "TaylrTooltip" }} title={<div className="TooltipContents">Redo</div>} placement="top">
                    <span>
                      <Button variant="contained" color="primary" onClick={handleRedo} disabled={(futureRef.current && futureRef.current.length == 0)  || streaming} className="SubmitButtonSmall">
                        <BiRedo className="CopyIcon"/>
                      </Button>
                    </span>
                  </Tooltip>
                  <Tooltip arrow classes={{ popper: "TaylrTooltip" }} title={<div className="TooltipContents">Regenerate the Result</div>} placement="top">
                    <span>
                      <Button variant="contained" color="primary" onClick={handleSubmit} disabled={(response && response.length == 0) || streaming} className="SubmitButtonSmall">
                        <BiRefresh className="CopyIcon"/>
                      </Button>
                    </span>
                  </Tooltip>
                  <Tooltip arrow classes={{ popper: "TaylrTooltip" }} title={<div className="TooltipContents">{copied ? "Copied!": "Copy to Clipboard"}</div>} placement="top">
                    <Button variant="contained" color="primary" onClick={handleCopy} disabled={false} className="NewButtonSmall">
                      <MdContentCopy className="CopyIcon"/>
                    </Button>
                  </Tooltip>
                </div>
              </div>
              <TextField
                id="responseInput"
                label={null} 
                multiline
                onChange={handleChange}
                rows={outputRows}
                disabled = {response && response.length === 0}
                value={response}
              />
              {location.pathname.endsWith("boolean-search") &&  <div className="CreatePageOutput" style={{marginTop: "10px"}}>
                  <div className="CreatePageButtonContainer" >
                    <a href={`http://www.google.com/search?q=${response} -intitle:"profiles" -inurl:"dir/+"+site:linkedin.com/in/+OR+site:linkedin.com/pub/`} target="_blank">
                      <Button variant="contained" color="primary" disabled={streaming} className="SearchLinkedInButton" style={{fontSize: "12px !important"}}>
                        Search LinkedIn X-Ray
                      </Button>
                    </a>
                  </div>
                </div>
                }
              {props.refine_output &&  
              <div className="RefineOutputContainer">
                <Collapse in={responded}>
                  <div className="CreatePageOutput" style={{marginTop: "10px"}}>
                      <div className="CreatePageInputLabel">
                        Refine the output?
                      </div>
                      <div className="RefineOutputExamples">
                        {refineOutputOptions.map((option, index) => {
                          return <div className="RefineOutputExample" onClick={() => {setInstructions(option.replace("...", " "))}}>{option}</div>
                        })}
                      </div>
                      <TextField
                        id="refineOutput"
                        label={null} 
                        multiline
                        onChange={handleChange}
                        rows={4}
                        disabled = {response && response.length === 0}
                        value={instructions}
                        
                      />
                      <div className="CreatePageButtonContainer" style={{marginTop: "10px"}}>
                        <Button variant="contained" color="primary" onClick={handleSubmit} disabled={streaming} className="SubmitButton" id="RefineButton">
                          Refine
                        </Button>
                      </div>
                    </div>
                  </Collapse>
                </div>
                }





            </div>
          </div>
        </div>
  );
};

export default CreateTemplateComponent;